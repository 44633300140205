import React, {useState, useEffect} from "react";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { Container, Row, Col } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {authentication} from './firebase';
import {TwitterAuthProvider, signInWithPopup} from 'firebase/auth';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {data_ex, showDialogue, changeShowDialogue} from './App';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Carousel from 'react-bootstrap/Carousel';
import {rel_stage, user_id, statuses_ex, sex_ex, ex_option_a, ex_option_b, ex_option_c, ex_option_d, ex_background} from './App';
import { getCountFromServer } from "firebase/firestore";
// import { Link } from 'react-router-dom';
import GooglePayButton from '@google-pay/button-react'
import mixpanel from 'mixpanel-browser';


const db = firebase.firestore();
var size_url
// var from_status, to_status

const SubmitSurvey = (survey) => {

    console.log('user_id')
    console.log(user_id)

    const [show, setShow] = useState(false);
    const [option_a, setoptionA]=useState(null);
    const [option_b, setoptionB]=useState(null);
    const [option_c, setoptionC]=useState(null);
    const [option_d, setoptionD]=useState(null);
    // const [from, setFrom]=useState(null);
    const [size,setSize]=useState('0');
    const [email,setEmail]=useState(null);
    mixpanel.init("ff34d02d72a629eebde8aa8650dc6023", {
    });
    // from_status = from

    function getSize(val)
  {
    // mixpanel.track('set size', {
    //     size: val.target.value,
    //     time: new Date().toLocaleString()
    //   } )
    setSize(val.target.value)
  }
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  function getEmail(val)
  {
    setEmail(val.target.value)
    // console.log('val')
    // console.log(val)
    // if(val.target?.value && val.target.value.match(isValidEmail)){
    //     setEmail(val.target.value)
    //     // upload()
    //   }else{
    //    alert('please provide a valid email')
    //   }
   
  }
  

    // if(from_status=='Away'){
    //     to_status = 'Home'
    // }
    // if(from_status=='Home'){
    //     to_status = 'Away'
    // }
    

    console.log('survey in page2')
    console.log(survey)

    const handleClose = () => {
      setShow(false);
    }

    function getOptionA(val){
        setoptionA(val.target.value)
    }

    function getOptionB(val){
        setoptionB(val.target.value)
    }

    function getOptionC(val){
        setoptionC(val.target.value)
    }

    function getOptionD(val){
        setoptionD(val.target.value)
    }

    function getToFrom(){
        db.collection("users").doc(user_id)
        .get()
        .then((doc) => {
        if (doc.exists) {
            var from = doc.data().status
            console.log('from')
            console.log(from)
            // setFrom(from); 
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
        
        });
    }

    function upload (){
        mixpanel.track('upload', {
            // email: email,
            // size: size,
            data: data_ex,
            background: ex_background,
            option_a: ex_option_a,
            option_b: ex_option_b,
            option_c: ex_option_c,
            option_d: ex_option_d,
            resp_stage: rel_stage,
            resp_sex: sex_ex,
            time: new Date().toLocaleString()
          } )
        // getEmail()
        if(size == '0' | email == null){
            alert('Please fill all field')
            return
        }else if(size=='5'){
            size_url = "https://square.link/u/L64myGTY?src=embd"
        }else if(size == '10'){
            size_url = "https://square.link/u/8ZSwzqWB"
        }else if(size == '20'){
            size_url = "https://square.link/u/sB0Q0FDc"
        }
        console.log(email)
        // getToFrom()
        var date = new Date().toLocaleString(),
        date_sig = date.replace(' ','')
        date_sig = date_sig.replace(':','')
        date_sig = date_sig.replace(':','')
        date_sig = date_sig.replace(',','')
        date_sig = date_sig.replace('/','')
        date_sig = date_sig.replace('/','')
        var final_date_sig = email+date_sig
        console.log('final_date_sig')
        console.log(final_date_sig)

       var docData = {
        question: data_ex,
        optionA: ex_option_a,
        optionB: ex_option_b,
        optionC: ex_option_c,
        optionD: ex_option_d,
        background: ex_background,
        uploader: user_id,
        date: date,
        date_Sig: final_date_sig,
        resp_stage: rel_stage,
        // to: to_status,
        // from: from_status,
        resp_sex: sex_ex,
        surveySize: size,
        resultEmail: email
       }

       db.collection("surveys").doc(final_date_sig).set(docData).then(() => {
        console.log("Document successfully written!");
        console.log(docData);
    });

    alert('Successful Upload! Survey result will be sent via email, once ready. Proceed to Payment.')

    handleClose()

    }


    useEffect(() => {

        if(showDialogue==true){
            setShow(true);
            changeShowDialogue()
        }
     
        },[showDialogue])

//    function pay(){
//     href= "https://square.link/u/utOI9RRp"
//    }
    return (
      // <div>
      
      <Modal show={show} onHide={handleClose} class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
     
                <Modal.Header closeButton>
                  <Modal.Title>{data_ex}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>
                <div class="col text-center">
                
                {/* <InputGroup className="text-muted" style={{ height: 40}}>
                <FloatingLabel
                controlId="floatingInput"
                label="Answer A?"
                className="mb-3"
                >
                    <Form.Control 
                        onChange={'getOptionA'}
                        placeholder=" Answer A?" />
                    <Form.Control.Feedback type="invalid">
                    your question?
                    </Form.Control.Feedback>
                    </FloatingLabel>
                </InputGroup>
              

                <InputGroup className="text-muted" style={{ height: 40, marginTop:5}}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Answer B?"
                    className="mb-3"
                    >
                    <Form.Control 
                        onChange={'getOptionB'}
                        placeholder=" Answer B?" />
                    <Form.Control.Feedback type="invalid">
                    username?
                    </Form.Control.Feedback>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="text-muted" style={{ height: 40, marginTop:5}}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Answer C?"
                    className="mb-3"
                    >
                    <Form.Control 
                        onChange={'getOptionC'}
                        placeholder=" Answer C?" />
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="text-muted" style={{ height: 40, marginTop:5}}>
                    <FloatingLabel
                    controlId="floatingInput"
                    label="Answer D?"
                    className="mb-3"
                    >
                    <Form.Control 
                        onChange={'getOptionD'}
                        placeholder=" Answer D?" />
                </FloatingLabel>
                </InputGroup> */}

                {/* <InputGroup  className="text-muted" style={{ height: 40, marginTop:5}}>
                    <FloatingLabel
                    controlId="floatingInput"
                    label="Give a brief context (optional)?"
                    className="mb-3"
                    >
                    <Form.Control 
                        onChange={'getOptionD'}
                        placeholder=" Give a brief context (optional)?"
                        placeholderTextColor = "white" />
                </FloatingLabel>
                </InputGroup> */}

                {/* <InputGroup className="mb-3" style={{ height: 40, marginTop:0}}>
                <Form.Select onChange={getData} style={{ marginTop: 0}}  aria-label="Default select example">
                   <option value="0"> What culture is she identified as? </option>
                   <option value="1"> African  </option>
                   <option value="2"> American (Black) </option>
                   <option value="2"> American (White) </option>
                   <option value="3"> Asian </option>
                   <option value="3"> Australian </option>
                   <option value="4"> Carribean </option>
                   <option value="5"> European </option>
                   <option value="5"> Indian </option>
                   <option value="6"> Latino </option>
                   <option value="6"> Middle Eastern </option> */}
                   {/* <option value="500">500 Ladies ($100) </option> */}
                   {/* <option value="1000">1000 Ladies ($200) </option> */}
                   {/* <option value="10000">10000 Ladies ($2000) </option> */}
               {/* </Form.Select>
                </InputGroup> */}

                <InputGroup style={{ height: 40, marginTop:0}}>
               <Form.Select onChange={getSize} style={{ marginTop: 0}}  aria-label="Default select example">
                   <option value="0"> Number of {rel_stage} {sex_ex} to ask? </option>
                   <option value="5">Ask 5 {rel_stage} { sex_ex} (£1) </option>
                   <option value="10">Ask 10 {rel_stage} { sex_ex} (£2) </option>
                   <option value="20">Ask 20 {rel_stage} { sex_ex} (£5) </option>
                   {/* <option value="50">50 ($10)</option> */}
                   {/* <option value="100">100 ($25) </option>
                   <option value="200">200 ($50) </option> */}
                   {/* <option value="500">500 Ladies ($100) </option> */}
                   {/* <option value="1000">1000 Ladies ($200) </option> */}
                   {/* <option value="10000">10000 Ladies ($2000) </option> */}
               </Form.Select>
               </InputGroup>

               <InputGroup className="text-muted" style={{ height: 20, marginTop:5, marginBottom:50}}>
                    <FloatingLabel
                    controlId="floatingInput"
                    label=" Enter email to receive Survey result."
                    className="mb-3"
                    >
                    <Form.Control 
                        onChange={getEmail}
                        placeholder=" Enter email to receive Survey result."
                        placeholderTextColor = "white" />
                </FloatingLabel>
                </InputGroup> 
             

                {/* <Button xs='auto' variant="primary" style={{ marginTop: 20, borderRadius:0,  height: 50}} onClick={upload}>
                Find Out
                </Button>  */}
                     <a href= {size_url}>
                        <button type="button" onClick={upload} style={{ marginLeft: 2, marginTop: 20, borderRadius:4, width: 125, height: 50}} className="btn btn-warning waves-effect">
                        <i className="fas fa-sun pr-2" aria-hidden="true"></i>   Send Survey
                        </button>
                    </a>  
                    {/* <GooglePayButton
                    environment='PRODUCTION'
                    // environment="TEST"
                        paymentRequest={{
                            apiVersion: 2,
                            apiVersionMinor: 0,
                            allowedPaymentMethods: [
                                {
                                    type: "CARD",
                                    parameters: {
                                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                        allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER', 'JCB'],
                                    },
                                    tokenizationSpecification: {
                                        type: 'PAYMENT_GATEWAY',
                                        parameters: {
                                            gateway: 'square',
                                            // gatewayMerchantId: 'squareGatewayMerchantId',
                                            // gatewayMerchantId: 'sq0idp-w46nJ_NCNDMSOywaCY0mwA',
                                            gatewayMerchantId: 'sq0idp-XMnkT03wOSldWZ80yCzang'
                                            // gatewayMerchantId: '05498866192997955200',
                                        },
                                    },
                                }
                            ],
                            merchantInfo: {
                                merchantId: 'BCR2DN4TQTJOZST3',
                                // merchantId: '05498866192997955200',
                                merchantName: 'PettySurvey.com',
                            },
                            transactionInfo:{
                                totalPriceStatus: 'FINAL',
                                totalPriceLabel: 'Total',
                                totalPrice: '1',
                                currencyCode: 'USD',
                                countryCode: 'US',
                                checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE"
                            },
                            shippingAddressRequired: false,
                            callbackIntents: ['PAYMENT_AUTHORIZATION'],
                        }}
                            onLoadPaymentData={paymentRequest => {
                                console.log('success', paymentRequest);
                            }}

                            onPaymentAuthorized={paymentData => {
                                console.log('Payment Authorized Success', paymentData)
                                return {transactionState: 'SUCCESS'}
                                }
                            }

                            // onPaymentDataChanged={paymentData => {
                            //     console.log('On Payment Data Changed', paymentData)
                            //     return { }
                            //  }
                            // }

                            existingPaymentMethodRequired="false"
                            buttonColor="black"
                            buttonType="Buy"
                    /> */}

                        {/* <div style={{marginTop: 20}}>
                        <a class="text-success">Success! You've succesfully submited your PettySurvey.</a>

                        </div>
                        <div>
                        <a>The survey result will be emailed to you in 5 mins</a>

                            </div>
                            <div>
                            <a style={{marginBottom: 40}}>Email admin@pettysurvey.com if you don't receive your result after an hour.</a>

                        </div> */}
                </div>
                  
                </Modal.Footer>
              
              </Modal>
             
     
              
      // </div>

    );
};

export default SubmitSurvey;