import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import IconButton from 'react-bootstrap/InputGroup';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { getByDisplayValue } from '@testing-library/react';
import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { Container, Row, Col } from 'react-bootstrap';
import { FieldPath } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TwitterShareButton, TwitterIcon,} from "react-share";
import {user_id, statuses_ex} from './App';



const db = firebase.firestore();


const Presurvey = ({prefeeds}) => {

    console.log('user_id')
    console.log(user_id)

    const handleLike = (prefeed) => {
         // search for survey in question
         var surveyRef = db.collection("surveys").doc(prefeed.date_Sig);
         // increase a score on firestore by 1
         surveyRef.update({
             support: firebase.firestore.FieldValue.increment(1),
            //  oppose: firebase.firestore.FieldValue.increment(1),
           });

    }
    const handleUnlike = (prefeed) => {

         // search for survey in question
         var surveyRef = db.collection("surveys").doc(prefeed.date_Sig);
         // increase a score on firestore by 1
         surveyRef.update({
            oppose: 'true'
           });
    }

    // var diff = Math.abs(new Date().toLocaleString()) c;
    // {(new Date() - new Date(prefeed.date))/(1000*60*60)}
    return (
    
        <ul class="list-group collapse show wrapper" style={{ margin: 10}}>
        {prefeeds.map((prefeed, id) => (
            <Button variant="light" style={{ margin: 10, marginTop: 40}}  className="d-grid gap-2">
            {/* <div><b class="mb-1"> Men to WoMen: </b> {prefeed.question} </div> */}
            {/* <div><b class="mb-1"> </b> <small class="text-muted">  </small></div> */}
            <div><b class="mb-1"> </b> Away to Home: <small class="text-muted"> {prefeed.question} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> {Math.floor(24 - (new Date(new Date().toLocaleString()) - new Date(prefeed.date))/(1000*60*60))} hours left for this post to go public </small></div>
            <div>
             <TwitterShareButton url={'question: ' + prefeed.question + ' from Men to Women'} title={'https://adultSurvey.xyz: '}>
             <TwitterIcon size={25} round />
              </TwitterShareButton>
              <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-free-vector-download-7.png" width="32" alt="Logo Whatsapp Png Free Vector Download" /> </a>
            </div>
            <Button variant="secondary"  > {prefeed.optionA} </Button>
            <Button variant="secondary"  > {prefeed.optionB} </Button>
            <Button variant="secondary"  > {prefeed.optionC} </Button>
            <Button variant="secondary"   > {prefeed.optionD} </Button>
            {/* <Button variant="outline-danger"   onClick={()=>handleLike(prefeed)}> Allow to go public👍 </Button> */}
            <Button variant="outline-danger"   onClick={()=>handleUnlike(prefeed)}>  Stop survey from going public 🛑 </Button>
            </Button>)
        
        )}

        </ul>

    );
};

export default Presurvey;