import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import IconButton from 'react-bootstrap/InputGroup';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { getByDisplayValue } from '@testing-library/react';
import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { Container, Row, Col } from 'react-bootstrap';
import { FieldPath } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TwitterShareButton, TwitterIcon,} from "react-share";
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {authentication} from './firebase';
import { arrayUnion, connectFirestoreEmulator, disableNetwork, QuerySnapshot, runTransaction } from "firebase/firestore";
import { getFirestore, doc, getDoc } from "firebase/firestore"
import Dropdown from 'react-bootstrap/Dropdown';
import {statuses_ex, user_id} from './App'
import Toast from 'react-bootstrap/Toast';



// var user_id
const db = firebase.firestore();
var ascore_per, bscore_per, cscore_per, dscore_per, optiona, optionb, optionc, optiond
var ascore, bscore, cscore, dscore, total_votes

const Survey = ({feeds}) => {

    console.log('user_id')
    console.log(user_id)
    console.log('status')
    console.log(statuses_ex)
   
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);
    const [statuses, setStatus] = useState(false);
    const [inputFields, setInputField] = useState([])
    const [question, setQuestion] = useState([])
    var [user_idd, setUser] = useState(0);
    const [data,setData]=useState(null);



    function getData(val)
  {
    setData(val.target.value)
  }
    // const [ascore, setAscore] = useState([])
    // const [bscore, setBscore] = useState([])
    // const [cscore, setCscore] = useState([])
    // const [dscore, setDscore] = useState([])
    // const [total_votes, setTotalVotes] = useState([])
    // user_id = user_idd

    function calc(){

     ascore_per = Math.floor((ascore/total_votes) * 100)
     bscore_per = Math.floor((bscore/total_votes) * 100)
     cscore_per = Math.floor((cscore/total_votes) * 100)
     dscore_per = Math.floor((dscore/total_votes) * 100)

     console.log('start')
     console.log(ascore_per)
     console.log(bscore_per)
     console.log(cscore_per)
     console.log(dscore_per)

    }

    const handleClickA = (feed) => {
        if(user_id=='0'){
            // signInWIthGoogle()
            alert('pls sign in')
            return
            }

        // check if user is home or away
        // check if survey is home or away
        var feed_status = feed.from
        if(statuses_ex == feed_status){
            alert('Sorry, only Home can vote on this one')
            return
        }
          

        // setAscore(feed.a_score)
        // setBscore(feed.b_score)
        // setCscore(feed.c_score)
        // setDscore(feed.d_score)

        ascore = feed.a_score
        bscore = feed.b_score
        cscore = feed.c_score
        dscore = feed.d_score

        total_votes = feed.total_votes

        // setTotalVotes(feed.total_votes)

        optiona = feed.optionA
        optionb = feed.optionB
        optionc = feed.optionC
        optiond = feed.optionD

        // search for survey in question
        var surveyRef = db.collection("surveys").doc(feed.date_Sig);
        // increase a score on firestore by 1
        surveyRef.update({
            a_score: firebase.firestore.FieldValue.increment(1),
            total_votes: firebase.firestore.FieldValue.increment(1),
          });

          setQuestion(feed.question)
          calc()
          console.log('inside feed')
          console.log(question)
          setShow(true)
    }
    
    const handleClickB = (feed) => {

        // setAscore(feed.a_score)
        // setBscore(feed.b_score)
        // setCscore(feed.c_score)
        // setDscore(feed.d_score)
        ascore = feed.a_score
        bscore = feed.b_score
        cscore = feed.c_score
        dscore = feed.d_score

        total_votes = feed.total_votes
        // setTotalVotes(feed.total_votes)
        optiona = feed.optionA
        optionb = feed.optionB
        optionc = feed.optionC
        optiond = feed.optionD
        // search for survey in question
        var surveyRef = db.collection("surveys").doc(feed.date_Sig);
        // increase a score on firestore by 1
        surveyRef.update({
            b_score: firebase.firestore.FieldValue.increment(1),
            total_votes: firebase.firestore.FieldValue.increment(1),
        });
        
        setQuestion(feed.question)
        calc()
        console.log('inside feed')
        console.log(question)
        setShow(true)
    } 
    
    const handleClickC = (feed) => {

        // setAscore(feed.a_score)
        // setBscore(feed.b_score)
        // setCscore(feed.c_score)
        // setDscore(feed.d_score)
        ascore = feed.a_score
        bscore = feed.b_score
        cscore = feed.c_score
        dscore = feed.d_score

        total_votes = feed.total_votes
        // setTotalVotes(feed.total_votes)
        optiona = feed.optionA
        optionb = feed.optionB
        optionc = feed.optionC
        optiond = feed.optionD
        // search for survey in question
        var surveyRef = db.collection("surveys").doc(feed.date_Sig);
        // increase a score on firestore by 1
        surveyRef.update({
            c_score: firebase.firestore.FieldValue.increment(1),
            total_votes: firebase.firestore.FieldValue.increment(1),
        });
        
        setQuestion(feed.question)
        calc()
        console.log('inside feed')
        console.log(question)
        setShow(true)
    } 
    
    const handleClickD = (feed) => {

        // setAscore(feed.a_score)
        // setBscore(feed.b_score)
        // setCscore(feed.c_score)
        // setDscore(feed.d_score)
        // setTotalVotes(feed.total_votes)
        optiona = feed.optionA
        optionb = feed.optionB
        optionc = feed.optionC
        optiond = feed.optionD
        
        ascore = feed.a_score
        bscore = feed.b_score
        cscore = feed.c_score
        dscore = feed.d_score

        total_votes = feed.total_votes
        // search for survey in question
        var surveyRef = db.collection("surveys").doc(feed.date_Sig);
        // increase a score on firestore by 1
        surveyRef.update({
            d_score: firebase.firestore.FieldValue.increment(1),
            total_votes: firebase.firestore.FieldValue.increment(1),
        });
        
        setQuestion(feed.question)
        calc()
        console.log('inside feed')
        console.log(question)
        setShow(true)
    }


    function getStatus(){
        setStatus(true)
    }


    const userExists = async ()=>{
        try {
          await runTransaction(db, async (transaction) => { 
    
          const docRef = doc(db, 'users', user_id);
          const docSnap = await getDoc(docRef);
    
              if (docSnap.exists()){
                // getLifes();
              }else{
                db.collection('users').doc(user_id).set({id: user_id, bdate: 'unknown', sex: 'unknown'}, {merge: true})   
                getStatus();
              }
            });
          } catch (e) {
          // console.log("Transaction failed: ", e);
        }
      }




    const signInWIthGoogle = ()=>{
        // console.log('sigining in...')
        const provider = new GoogleAuthProvider();
        signInWithPopup(authentication, provider)
        .then((re)=>{
          var user_id = re.user.uid
          setUser(user_id)
          console.log(user_id);
          userExists()
        })
        .catch((error) => {
          // console.log(error);
          alert('error on login, pls refresh and try again')
        })
        // window.location.reload();
      }


      function statusUpload(){
        if (data == 2){
            console.log('away')
        // search for survey in question
         var userRef = db.collection("users").doc(user_id);
         // increase a score on firestore by 1
         userRef.update({
            status: 'Away'
           });
           setStatus(false)
           handleClose()
        }if (data == 1){
            console.log('Home')
             // search for survey in question
         var userRef = db.collection("users").doc(user_id);
         // increase a score on firestore by 1
         userRef.update({
            status: 'Home'
           });
           setStatus(false)
           handleClose()
        }if (data == 0){
          alert('please choose a location')
          return
        }
        console.log('data')
        console.log(data)
        setShow(false)
      }


    return (
    
        <div class="list-group collapse show wrapper" style={{ margin: 10}}>
        {feeds.map((feed, id) => (
            <Button variant="light" style={{ margin: 10, marginTop: 20, paddingBottom: 20}} className="d-grid gap-2">
            {/* <div><b class="mb-1"> </b> {feed.question} </div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> {feed.question} </small></div>
            <div>
             <TwitterShareButton url={'question: ' + feed.question + ' from Men to Women'} title={'https://japachat.com: '}>
             <TwitterIcon size={25} round />
              </TwitterShareButton>
              <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-free-vector-download-7.png" width="32" alt="Logo Whatsapp Png Free Vector Download" /> </a>
            </div>
            <Button variant="outline-danger"  onClick={()=>handleClickA(feed)}> {feed.optionA} </Button>
            <Button variant="outline-danger"   onClick={()=>handleClickB(feed)}> {feed.optionB} </Button>
            <Button variant="outline-danger"  onClick={()=>handleClickC(feed)}> {feed.optionC} </Button>
            <Button variant="outline-danger"   onClick={()=>handleClickD(feed)}> {feed.optionD} </Button>
            </Button>)
        
        )}

        <Modal show={show} onHide={handleClose} class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
        <Modal.Header closeButton>
          <Modal.Title>{question}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
            <ProgressBar style={{marginTop: 0}} variant="info" now={ascore_per} label={`${optiona + " ( " + ascore_per + " ) "}%`}/>
            <ProgressBar style={{marginTop: 10}} variant="info" now={bscore_per} label={`${optionb + " ( " + bscore_per + " ) "}%`}/>
            <ProgressBar style={{marginTop: 10}} variant="info" now={cscore_per} label={`${optionc + " ( " + cscore_per + " ) "}%`}/>
            <ProgressBar style={{marginTop: 10}} variant="info" now={dscore_per} label={`${optiond + " ( " + dscore_per + " ) "}%`}/>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={statuses} class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
        <Modal.Header closeButton>
        <Modal.Title>You wont be able to change this for a while.</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Select onChange={getData} aria-label="Default select example">
                <option value="0">Are you currently Home or Away?</option>
                <option value="1">I'm Home</option>
                <option value="2">I'm Away</option>
             </Form.Select>
        </Modal.Body>

        <Modal.Footer>
        <Button onClick={statusUpload} variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>


      {/* <Toast show={showToast} onClose={handleCloseToast}> */}
      {/* <Toast.Header> */}
        {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
        {/* <strong className="me-auto">JaPaChat</strong> */}
        {/* <small>1 mins ago</small> */}
      {/* </Toast.Header> */}
      {/* <Toast.Body>Sorry, only Home can vote on this one</Toast.Body> */}
    {/* </Toast> */}

        </div>

    );
};

export default Survey;