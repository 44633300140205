// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAes48-Q5oQgG3v-khMu6AJSt2xm33qrEA",
    authDomain: "adultsurvey-4aefc.firebaseapp.com",
    projectId: "adultsurvey-4aefc",
    storageBucket: "adultsurvey-4aefc.appspot.com",
    messagingSenderId: "225266687507",
    appId: "1:225266687507:web:6dd2c62a4957563f3d29e6",
    measurementId: "G-W5JLTQHY9N"
};

firebase.initializeApp(firebaseConfig)

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firestore = getFirestore(app)
export const authentication = getAuth(app);